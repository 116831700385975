import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const VentureLabContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allVentureLabsJson {
          edges {
            node {
              id
              pageTitle
              heroBannerSection {
                bannerImage
                heading
                content
                actionButton {
                  buttonLabel
                  buttonPath
                }
              }
              ventureLabsSection {
                hideSection
                heading
                content
                tabs {
                  tabName
                  logos {
                    logo
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allVentureLabsJson: { edges: VentureLabsData } }) =>
      children(
        VentureLabsData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

VentureLabContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default VentureLabContainer;
