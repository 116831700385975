import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import SEO from "../SEO/seo";
import Section from "../Section";
import PageHeroBanner from "../PageHeroBanner";
import VentureLabContainer from "../../containers/VentureLabsContainer";
import LinkTo from "../LinkTo";
import AspectRatio from "../AspectRatio";
import BgImage from "../BgImage";
import MarkdownToHtml from "../MarkdownToHtml";

const VentureLabsPage = () => {
  const theme = useContext(ThemeContext);
  return (
    <VentureLabContainer>
      {(VentureLabsData) => {
        const pageId = "innovation";
        const displayTabSection = false;
        const VentureLabsInfo = VentureLabsData.find(
          (data) => data.id === pageId
        );
        const { heroBannerSection, ventureLabsSection } = VentureLabsInfo;
        const { tabs } = ventureLabsSection;
        const [tab, setTab] = useState(tabs[0]);
        const tabArray = [];
        tabs.map((t) => {
          tabArray.push(t.tabName);
        });

        const isActiveTab = tabArray.map((t) => {
          if (t === tab.tabName) {
            return true;
          }
        });

        const toggleActiveButton = (tab) => {
          setTab(tab);
        };
        return (
          <>
            <SEO
              pathname="innovation"
              templateTitle={VentureLabsInfo.pageTitle}
            />
            {heroBannerSection && (
              <PageHeroBanner
                actionButton={heroBannerSection.actionButton}
                bannerImage={heroBannerSection.bannerImage}
                content={{
                  heading: heroBannerSection.heading,
                  text: heroBannerSection.content,
                }}
              />
            )}
            {ventureLabsSection && !ventureLabsSection.hideSection && (
              <Section
                sx={{
                  bg: "grayBackground",
                }}
              >
                {ventureLabsSection && (
                  <MarkdownToHtml
                    content={ventureLabsSection.content}
                    heading={ventureLabsSection.heading}
                    headingSx={{
                      ...theme.designElement.overLine,
                    }}
                    sx={{
                      mb: 5,
                      textAlign: ["center", null, null, "left"],
                    }}
                  />
                )}
                {displayTabSection && (
                  <Box
                    sx={{
                      bg: "white",
                      borderColor: "grays.4",
                      borderStyle: "solid",
                      borderWidth: 1,
                      ...theme.designElement.rightBottom,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {tabs &&
                        tabs.map((tab, index) => (
                          <Box
                            onClick={() => toggleActiveButton(tab)}
                            sx={{
                              alignItems: "center",
                              cursor: isActiveTab[index]
                                ? "default"
                                : "pointer",
                              px: [2, null, null, 4],
                              py: 3,
                            }}
                          >
                            <Box
                              sx={{
                                ":before": {
                                  bg: "grayBorder",
                                  bottom: "-15px",
                                  content: "''",
                                  height: "3px",
                                  mx: "auto",
                                  position: "absolute",
                                  width: isActiveTab[index] ? "100%" : "0%",
                                },
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  color: "text.para",
                                  fontSize: [1, null, null, 2],
                                  fontWeight: "bold",
                                  my: "auto",
                                  px: 2,
                                }}
                              >
                                {`${tab.tabName.toUpperCase()}`}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                    <Box
                      sx={{
                        p: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          m: [-2, null, null, -4],
                        }}
                      >
                        {tab.logos &&
                          tab.logos.map((l) => {
                            const { logo, url } = l;
                            return (
                              <>
                                {url ? (
                                  <LinkTo
                                    isLink={url}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      p: [2, null, null, 4],
                                      // position: "relative",
                                      width: ["100%", null, "50%", "33.33%"],
                                    }}
                                    target="_blank"
                                  >
                                    <AspectRatio
                                      ratio={[2, 1]}
                                      sx={{
                                        "&:hover": {
                                          bg: "grayBackground",
                                        },
                                      }}
                                    >
                                      <BgImage
                                        name={logo}
                                        page="portfolio"
                                        sx={{
                                          backgroundSize: "contain",
                                          position: "absolute",
                                        }}
                                      />
                                    </AspectRatio>
                                  </LinkTo>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      p: [2, null, null, 4],
                                      width: ["100%", null, "50%", "33.33%"],
                                    }}
                                  >
                                    <AspectRatio
                                      ratio={[2, 1]}
                                      sx={{
                                        "&:hover": {
                                          bg: "grayBackground",
                                        },
                                      }}
                                    >
                                      <BgImage
                                        name={logo}
                                        page="portfolio"
                                        sx={{
                                          backgroundSize: "contain",
                                          position: "absolute",
                                        }}
                                      />
                                    </AspectRatio>
                                  </Box>
                                )}
                              </>
                            );
                          })}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Section>
            )}
          </>
        );
      }}
    </VentureLabContainer>
  );
};

VentureLabsPage.propTypes = {};

export default VentureLabsPage;
