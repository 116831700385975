import React from "react";
import { Router } from "@reach/router";
import VentureLabsPage from "../components/VentureLabsPage";
import NotFoundPage from "./404";

const ventureLabsPageRouter = (props) => (
  <Router>
    <VentureLabsPage {...props} path="/innovation" />
    <NotFoundPage default />
  </Router>
);

export default ventureLabsPageRouter;
